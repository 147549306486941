<template>
  <div>
    <credential-aside
      v-if="aside"
      :active="true"
      :resource="credential"
      :parent="parent"
      @changed="changed"
      @aside="handleAside"
    ></credential-aside>

    <v-card>
      <v-row>
        <!-- <v-col
          cols="12"
          sm="3"
        >
          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              @click="aside = 'create'"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add credential</span>
            </v-btn>
          </div>
        </v-col> -->
      </v-row>

      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.channel`]="{item}">
          {{ t(item.channel.name) }}
        </template>

        <template #[`item.value_hint`]="{item}">
          {{ item.value_hint }}
          <div
            v-for="credential in item.children"
            :key="credential.key"
          >
            <b>{{ credential.distributor.slug }}</b> - {{ credential.value_hint }}
            <v-icon
              size="20"
              class="me-2"
              @click="setItem(credential)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
          </div>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="setItem(item, 'update')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="setParent(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span>Add Alternate Dist Value</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { dateTime, price } from '@core/utils/filter'
import {
mdiDotsVertical, mdiPencil, mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import CredentialAside from '../credential-resource/CredentialAside.vue'

// composition function
import useCredentialList from './useCredentialList'

export default {
  components: { CredentialAside },

  filters: { price, dateTime },

  setup() {
    const {
      listTable, searchQuery, tableColumns, options, statusFilter, totalListTable, loading, selectedTableData, loadCredentials,
    } = useCredentialList({
      root_only: 1,
    })

    const aside = ref(false)
    const credential = ref(null)
    const parent = ref(null)

    const setItem = (item, action = 'credential') => {
      credential.value = item
      parent.value = null
      aside.value = action
    }

    const setParent = (item, action = 'credential') => {
      credential.value = null
      parent.value = item
      aside.value = action
    }

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
        credential.value = false
        loadCredentials()
      }
    }

    const changed = () => {
      aside.value = false
      loadCredentials()
      parent.value = false
    }

    return {
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      t,

      aside,
      credential,
      setItem,
      setParent,
      parent,
      handleAside,
      loadCredentials,
      changed,

      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiPlus,
      },
    }
  },
}
</script>

    <style lang="scss" scoped>
    .invoice-list-search {
      max-width: 10.625rem;
    }
    .invoice-list-status {
      max-width: 11.3rem;
    }
    </style>
