var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside)?_c('credential-aside',{attrs:{"active":true,"resource":_vm.credential,"parent":_vm.parent},on:{"changed":_vm.changed,"aside":_vm.handleAside}}):_vm._e(),_c('v-card',[_c('v-row'),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.listTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.channel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.t(item.channel.name))+" ")]}},{key:"item.value_hint",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value_hint)+" "),_vm._l((item.children),function(credential){return _c('div',{key:credential.key},[_c('b',[_vm._v(_vm._s(credential.distributor.slug))]),_vm._v(" - "+_vm._s(credential.value_hint)+" "),_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"},on:{"click":function($event){return _vm.setItem(credential)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.setItem(item, 'update')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.setParent(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Add Alternate Dist Value")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }